:root {
  --clr-primary-100: #fff;
  --clr-primary-900: #000;

  --success: #198754;
  --error: #dc3545;
  --warning: #e2b70a;

  --ff-primary: "Raleway", sans-serif;
  --ff-secondary: "Roboto", sans-serif;
  --ff-title: "Playfair Display", serif;

  --fw-primary: 400;
  --fw-title: 600;
}


*,
*::before,
*::after {
  box-sizing: border-box;
}


.App {
  font-family: var(--ff-primary);
  font-weight: var(--fw-primary);
  font-size: 1.3125rem;
  line-height: 1.6;
  width: 100%;
  height: 100%;
}

.App,
h1,
h2,
h3,
p {
  margin: 0;
}

.number{
  font-family: var(--ff-secondary);
}

h1,
h2,
h3 {
  color: var(--clr-primary-400);
  font-family: var(--ff-accent);
  font-weight: 900;
  line-height: 1;
}

h2,
h3,
p {
  margin-bottom: 1em;
}

img {
  display: block;
  max-width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}

.header-wrapper {
  height: 15%;
}
header {
  padding: 0rem 0;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--clr-primary-100);
}

section {
  padding: 4rem 0;
}

.container {
  margin-inline: auto;
  width: min(90%, 70.5rem);
}

.split {
  display: flex;
  flex-direction: column;
}

@media (min-width: 40em) {
  .split {
    flex-direction: row;
  }

  .split > * {
    flex-basis: 100%;
  }

  .split > * + * {
    margin-left: 2em;
  }
  section {
    padding: 7rem 0;
  }
}

.container--narrow {
  max-width: 34rem;
}

.bg-light {
  background-color: var(--clr-primary-200);
}

.bg-primary {
  color: var(--clr-neutral-100);
  background-color: var(--clr-primary-400);
}

.bg-dark {
  color: var(--clr-neutral-100);
  background-color: var(--clr-neutral-900);
}

.bg-primary h2,
.bg-dark h2 {
  color: var(--clr-primary-200);
}

/* upload form styles */
.plus-form{
  margin: 30px auto 10px;
  text-align: center;
}
.plus-label-input{
  height: 0;
  width: 0;
  opacity: 0;
}
.plus-label{
  display: block;
  width: 68px;
  height: 68px;
  border: 1px solid black;
  border-radius: 50%;
  margin: 20px auto;
  line-height: 60px;
  color: black;
  font-weight: bold;
  font-size: 68px;
}
.plus-label:hover{
  background: black;
  color: white;
}
.plus-label-modal{
  display: block;
  width: 68px;
  height: 68px;
  border: 1px solid black;
  border-radius: 50%;
  margin: 20px auto;
  line-height: 55px;
  color: black;
  font-weight: bold;
  font-size: 68px;
}
.plus-label-modal:hover{
  background: black;
  color: white;
}
.plus-output{
  height: 60px;
  font-size: 0.8rem;
}
.plus-error{
  color: var(--error);
}

.progress-bar{
  height: 5px;
  background-color: black;
  color: black;
  margin-top: 20px;
}

.departmentContainer{
  border-radius: 0px;
  padding:2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.departmentContainer:hover{
  border: 1px solid lightgrey;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  cursor: pointer;
}

.productContainer{
  border: 1px solid lightgrey;
  border-radius: 0px;
  padding: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  height: 100%;
}
.productContainer:hover{
  border: 1px solid lightgrey;
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
  cursor: pointer;
}

.addressSelector {
  font-size: 0.8em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.addressSelector:hover{
  cursor: pointer;
}
.addressSelected{
  font-size: 0.8em;
  font-weight: bold;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.thumbnailPic{
  border: 0.5px solid grey;
  cursor: pointer;
}
.thumbnailPicSelected{
  border: 2px solid black;
  cursor: pointer;
}
.checkAdded {
  font-size: 1em;
  color: green;
}
.errorColor{
  color: rgb(139,0,0);
  background-color: rgb(255,0,0,0.2);
}
.redDot{
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
.greenDot{
  height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

