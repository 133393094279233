.button {
    font-size: 0.75em;
    background-color: black; /* Green */
    border: none;
    color: white;
    padding: 12px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  .button:hover{
    background-color: grey;
    cursor: pointer;
  }
  
  @media only screen and (min-width: 651px){
    .button {
      font-size: 0.75em;
      background-color: black; /* Green */
      border: none;
      color: white;
      padding: 12px 0px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }