.how_it_works_body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}
.sections{
    display: flex;
    justify-content: space-evenly;
    min-width: 375px;
    max-width: 450px;
}
.section_title{
    font-size: 1.25em;
    margin: 1.0em;
    margin-top: 0;
}
.section_info{
    line-height: 1.5em;
    margin: 1.0em;
}
.number_circle{
    border-radius: 50%;
    min-width: 48px;
    height: 48px;
    padding: 7.5px;
    background: #000000;
    border: 3px solid #000;
    color: #ffffff;
    text-align: center;
    font: 24px Arial, sans-serif;
    margin: 0.25em;
  }
  .notes_section{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
  .notes{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }