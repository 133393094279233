.decrement{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid grey;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .decrement:hover{
    cursor:pointer;
  }
  .increment{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid grey;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .increment:hover{
    cursor:pointer;
  }
  .quantity{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    font-size: 0.8em;
  }
  .remove{
    margin-left: 20px;
    cursor: pointer;
    align-self: flex-end;
    font-size: 0.8em;
  }