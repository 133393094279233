.noselect {
    display: inline-block;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.nonDays{
    border: 1px solid black;
    width: 100%;
    padding: 5px 0 5px 0;
    background: gray;
}
.nonChosenAvailableDay{
    border: 1px solid black;
    width: 100%;
    padding: 5px 0 5px 0;
    cursor: pointer;
    background: white;
}
.bookedOff{
    border: 1px solid black;
    width: 100%;
    background: red;
    opacity: 0.5;
    padding: 5px 0 5px 0;
}
.chosenDay{
    border: 1px solid black;
    width: 100%;
    padding: 5px 0 5px 0;
    cursor: pointer;
    background: lightgreen;
}
.blockedDays{
    border: 1px solid black;
    width: 100%;
    padding: 5px 0 5px 0;
    background: lightgray;
}
.timeslotsAvailable{
    border: 1px solid black;
    padding: 1em;
    text-align:center;
    cursor:pointer;
}
.timeslotsUsed{
    border: 1px solid black;
    padding: 1em;
    text-align: center;
    background: red; opacity: 0.5;
}
.deliveryTime{
    background: lightgreen;
    border: 1px solid black;
    padding: 5px;
}